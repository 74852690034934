import { ConfigProvider } from "antd";
import "antd/dist/antd.min.css";
import jaJP from "antd/lib/locale/ja_JP";
import App from "App";
import "helper/i18n";
import "moment/locale/ja";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={jaJP}>
    <App />
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
